/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import ParseContent from 'components/shared/ParseContent'
import CaseQuote from 'components/shared/CaseQuote'
import ButtonDefault from 'components/elements/ButtonDefault'
import CaseInfo from 'components/shared/CaseInfo'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const ContactLink = styled.div``

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpCases(wordpress_id: { eq: $wordpress_id }) {
      ...CasesFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, title, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero className="mb-lg-3" article backUrl="/cases/" title={parse(title)} content={acf.banner} />

      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <ParseContent content={acf.content.intro} />
          </div>
          <div className="col-md-4">
            <CaseInfo content={acf.content} />
          </div>
        </div>
      </div>

      <CaseQuote content={acf.quote} />

      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <ParseContent content={acf.content.story} />
          </div>
          <div className="col-md-4" />
          <div className="col-md-12">
            <ContactLink className="py-5 mt-5 d-flex flex-wrap justify-content-center justify-content-lg-start px-5 align-items-center">
              <p className="mb-md-0 mb-3 mr-lg-4 text-center font-size-xm font-weight-xl">Benieuwd naar hoe Clear Mind u kan helpen?</p>
              <ButtonDefault icon="arrow" to="/contact">
                <p>Neem contact op</p>
              </ButtonDefault>
            </ContactLink>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default PageTemplate
