import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

// Images
import Quote from 'img/icon/quote.inline.svg'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledCaseQuote = styled.div``

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  position: absolute !important;
`

const ImgContainer = styled(motion.div)`
  position: relative;
`

const QuoteContent = styled.div`
  z-index: 1;
  p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.mx};
  }
`

const QuoteWrapper = styled.div`
  p {
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const QuoteContainer = styled.div``

const CaseQuote = ({ className, content }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.9,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledCaseQuote ref={ref} className={`${className ? `${className}` : ``}`}>
      <ImgContainer 
        className="d-flex align-items-center justify-content-center"
        initial={{ height: 0 }}
        animate={isVisible || view ? { height: 296 } : {}}
        transition={{
          duration: 1,
          type: 'spring',
          delay: 0.4
        }}
      >
        <StyledImg loading="eager" fadeIn={false} fluid={content.image.localFile.childImageSharp.fluid} alt="" />
        <QuoteContent>
          <div className="d-flex justify-content-center">
            <Quote className="mb-3" />
          </div>
          <QuoteContainer>
            <QuoteWrapper>
              {parse(content.text)}
            </QuoteWrapper>
          </QuoteContainer>
        </QuoteContent>
      </ImgContainer>
    </StyledCaseQuote>
  )
}

export default CaseQuote